import axios from 'axios'
import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import url from '../url'
import Spinner from 'react-bootstrap/Spinner'
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Icon from '../../assets/fda/01.png'
import HideNav from './HideNav'
import FDADOC from '../../assets/fda/usfda_response- Instructions for use .pdf'
const SignIn = () => {
  const { t, i18n } = useTranslation()
  const [error, setError] = useState('')
  const [errors, setErrors] = useState([])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [spin, setSpin] = useState('')
  const [loginsuccess, setLoginSuccess] = useState(false)
  const [redirects, setRedirect] = useState('')


  const login = () => {

    // console.log("login")
    // console.log(sessionStorage.getItem('diagnosis_key'))

    sessionStorage.getItem('diagnosis_key')


    setError('')
    var postData = {
      email: email,
      password: password,
      unique_id: localStorage.getItem('diagnosis_key')
      // user_key:'6ARMA6V2YM'
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',

      }
    };

    if (email == '' || password == '') {
      setError(t('responses.fields_required'))
      return;
    }
    setSpin('border')


    axios.post(url + 'auth/login', postData, axiosConfig
    )
      .then(res => {
        console.log(res.data.result)
        sessionStorage.setItem('response', res.data.result);
        sessionStorage.setItem('mytoken', res.data.result.accessToken);
        sessionStorage.setItem('username', res.data.result.user.name);
        sessionStorage.setItem('useremail', res.data.result.user.email);
        setLoginSuccess(true)
        localStorage.removeItem('diagnosis_key');


      }).catch(e => {

        setError('Wrong username or password')
        console.log(e)


      }).then(r => {
        setSpin('false')

      }
      )
  }
  return (
    <div className="container" style={{ height: "100vh" }}>
      {loginsuccess ? (<>
        <Redirect to="/dashboard" />

      </>) : (<></>)}


      <div className="row">
        <div className="col-sm-3">
          <HideNav />
        </div>
        <div className="col-sm-6" >


          {/* <Card style={{ backgroundImage: "url(/assets/bg.jpg)" }}> */}
          {/* <Card.Header> <center><Image size='medium' src='../../assets/docsun.png' /> </center></Card.Header> */}
          <Card style={{  borderColor: 'white', border: 'rounded-pill', borderRadius: "10px" }} >
            <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <img src='../assets/Webappimages16/Logo.png' class="responsive" />

              </div>
              <div className="col-sm-3" />
            </div>
            <div class="mb-3 text-center">
                  <h4 class=" text-center">
                    <b>{t('login.title')}</b>
                  </h4>
                  <span class="text-center">
                    <h3> {t('login.subtitle')}</h3>
                  </span>
            </div>
            <div className="logininstruction">
              <ol>
                <p>
                  <li>{t('login.1')}</li>
                  <li>{t('login.2')}</li>
                  <li>{t('login.3')}</li>
                </p>
              </ol>
              
            </div>
            
            <Card.Body>
              <Card.Title>
                
              </Card.Title>


              


              <div className="row">
                <div className="col-sm-12">
                  <Spinner animation={spin} variant="primary" />
                  {error ? <>
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  </> : <></>}

                </div>
              </div>
              <div className="form-group">
                <label className=" bold">
                  {t('login.form.email')}

                </label>
                <input type="email" className="form-control" name="email" placeholder={t('login.form.email_placeholder')}
                  onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>
              <div className="form-group">
                <label className=" bold">
                  {t('login.form.password')}

                </label>
                <input type="password" className="form-control" placeholder={t('login.form.password_placeholder')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="row">
                <div className="col-sm-5" />
                <div className="col-sm-6">
                  <button height="70px" className="btn btn-sm" style={{ background: "#1d7ced", borderColor: "#269E9E", color: "#ffffff" }} onClick={login}> {t('login.form.signin')} <i class="fas fa-arrow-right"></i></button>


                </div>
                
              </div>
              <div className="row">
                <div className="col-sm-2" />
                
                <div className="col-sm-4">

                  <Button variant="link" as={Link} to="/signup" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>{t('login.form.create_account')}</Button>


                </div>
                <div className="col-sm-6">
                  <Button variant="link" as={Link} to="/auth/send-link" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>{t('login.form.password_rest')}</Button>

                  
                  
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <img src={Icon} style={{height:'50px'}} /> Please read instructions for use <a href={FDADOC} target="_blank">here</a>
                </div>

              </div>



            </Card.Body>
            {/* </Card> */}
          </Card>
          <br />
          <div className="row">
            <div className="col-sm-3">

            </div>
            <div className="col-sm-6">
              <center>

                {/* <Button variant="link" as={Link} to="/download" style={{ background: "#1d7ced", borderColor: "#28696D", color: "#ffffff" }} className="btn btn-primary btn-sm shadow" > <i class="fa fa-arrow-down" aria-hidden="true"></i>{t('login.form.download_app')}</Button> */}
              
              {/* <span style={{ color: "#ffffff", fontWeight: "600" }}>v1.2.0 (2021/06/14)</span><br />
                  <center> {t('login.form.ios')} <a href="mailto:contactus@docsun.health">{t('login.form.ios_link')}</a> </center> */}


            </center>
            </div>
          </div>

        </div>
        
      </div>

    </div >

  )
}


export default SignIn